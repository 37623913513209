@font-face {
  font-family: "Lexend";
  src: url(../../fonts/Lexend.ttf) format("truetype");
}

#notfound .content {
  font-family: "Lexend", sans-serif;
  font-size: 20pxrem;
  padding: 1rem;
  margin: 1rem;
}

#notfound header {
  text-align: center;
  padding: 15px;
  background-color: #69dc9e;
  color: white;
}

#notfound header h1 {
  font-size: 50px;
}

#notfound header h2 {
  font-size: 30px;
}

#notfound p {
  text-align: center;
  font-size: 20px;
}
