.dashboard-home-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-columns: repeat(4, 1fr); /* Ensures a maximum of 4 columns */
  grid-gap: 20px;
}

.dashboard-home-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  text-decoration: none;
  color: black;
  transition: background-color 0.3s ease;
  text-align: left;
}

.dashboard-home-card:hover {
  background-color: #e0e0e0;
}

.module-info {
  width: 100%;
}

.module-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.switch {
  margin-right: 10px;
}

.switch-welcomeMessages,
.switch-leaveMessages,
.switch-moderation,
.switch-fun,
.switch-utility,
.switch-levels,
.switch-logging {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-welcomeMessages input,
.switch-leaveMessages input,
.switch-moderation input,
.switch-fun input,
.switch-utility input,
.switch-levels input,
.switch-logging input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-welcomeMessages .slider,
.switch-leaveMessages .slider,
.switch-moderation .slider,
.switch-fun .slider,
.switch-utility .slider,
.switch-levels .slider,
.switch-logging .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 1s;
}

.switch-welcomeMessages .slider:before,
.switch-leaveMessages .slider:before,
.switch-moderation .slider:before,
.switch-fun .slider:before,
.switch-utility .slider:before,
.switch-levels .slider:before,
.switch-logging .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 1s;
}

.switch-welcomeMessages input:checked + .slider,
.switch-leaveMessages input:checked + .slider,
.switch-moderation input:checked + .slider,
.switch-fun input:checked + .slider,
.switch-utility input:checked + .slider,
.switch-levels input:checked + .slider,
.switch-logging input:checked + .slider {
  background-color: #2196f3;
}

.switch-welcomeMessages input:checked + .slider:before,
.switch-leaveMessages input:checked + .slider:before,
.switch-moderation input:checked + .slider:before,
.switch-fun input:checked + .slider:before,
.switch-utility input:checked + .slider:before,
.switch-levels input:checked + .slider:before,
.switch-logging input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.button {
  display: inline-block;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #69dc9e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Lexend, sans-serif;
}

.button:hover {
  background-color: #187bcd;
}

#config .content {
  font-family: "Lexend", sans-serif;
  font-size: 20pxrem;
  padding: 1rem;
  margin: 1rem;
}

#config header {
  text-align: center;
  padding: 15px;
  background-color: #69dc9e;
  color: white;
}

#config header h1 {
  font-size: 50px;
}

#config header h2 {
  font-size: 30px;
}
