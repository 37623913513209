.embed-customisation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  background-color: #2f3136;
  color: #dcddde;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.embed-customisation::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 5px;
  background-color: #7289da;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.embed-customisation .color-dot {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #7289da;
  cursor: pointer;
}

.embed-customisation .author,
.embed-customisation .footer {
  display: flex;
  align-items: center;
}

.embed-customisation .author-icon,
.embed-customisation .footer-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #202225;
  margin-right: 10px;
  cursor: pointer;
}

.embed-customisation input[type="text"],
.embed-customisation select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #202225;
  background-color: #202225;
  color: #dcddde;
  width: 100%;
}

.embed-customisation button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #7289da;
  color: white;
  cursor: pointer;
}

.embed-customisation button:hover {
  background-color: #5865f2;
}

.embed-customisation div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.color-picker {
  position: absolute;
  z-index: 2;
}

.color-picker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}