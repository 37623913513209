@font-face {
  font-family: "Lexend";
  src: url(../../../fonts/Lexend.ttf);
}

body {
  font-family: "Lexend", sans-serif;
}

#welcomeMessages .content {
  font-family: "Lexend", sans-serif;
  font-size: 20pxrem;
  padding: 1rem;
  margin: 1rem;
}

#welcomeMessages header {
  text-align: center;
  padding: 15px;
  background-color: #69dc9e;
  color: white;
}

#welcomeMessages header h1 {
  font-size: 50px;
}

#welcomeMessages header h2 {
  font-size: 30px;
}
#welcomeMessages .backButton {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

#welcomeMessages .backButton a {
  font-family: Lexend;
  text-decoration: none;
  color: black;

}

#welcomeMessages .backButton:hover {
  background-color: #f6f6f6;
}

#welcomeMessages .tab-container {
  width: 200px;
  height: 50px;
  border: 1px solid #000;
  display: flex;
  margin-bottom: 20px;
}

#welcomeMessages .tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#welcomeMessages .tab input {
  display: none;
}

#welcomeMessages .tab label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
}

#welcomeMessages input:checked + label {
  background-color: #ddd;
}

#welcomeMessages .textInput textarea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  padding: 10px;
  font-size: 20px;
  font-family: "Lexend", sans-serif;
 
}

#welcomeMessages .input-field {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

#welcomeMessages .input-field textarea {
  min-height: 100px;
  resize: vertical;
}

#welcomeMessages .select-field {
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

#welcomeMessages .colourSelectorButton {
  background-color: #ddd;
  border: none;
  border-radius: 10px;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}