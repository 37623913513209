@font-face {
  font-family: "Lexend";
  src: url(../../../fonts/Lexend.ttf);
}

body {
  font-family: "Lexend", sans-serif;
}

#serverSettings .content {
  display: flex;
  flex-direction: column;
  /* Add this line */
  gap: 1px;
  /* Adjust this value as needed */
}

#serverSettings section:nth-child(3) {
  order: 1;
}

#serverSettings section:nth-child(4) {
  order: 2;
}

#serverSettings section:nth-child(5) {
  order: 3;
}

#serverSettings section:nth-child(6) {
  order: 4;
}

#serverSettings header {
  text-align: center;
  padding: 15px;
  background-color: #69dc9e;
  color: white;
}

#serverSettings header h1 {
  font-size: 50px;
}

#serverSettings header h2 {
  font-size: 30px;
}

#serverSettings section {
  display: inline-block;
  /* Change display property to inline-block */
  vertical-align: top;
  /* Align sections to the top */
  background-color: #f5f5f5;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  width: 350px;
  /* Adjust as needed */
  height: auto;
}

#serverSettings section div div {
  display: flex;
  justify-content: left;
  align-items: center;
  width: auto;
  /* Change this from '10px' to 'auto' */
}

#serverSettings section h2 {
  color: #333;
  font-size: 24px;
}

#serverSettings section h3 {
  margin-right: auto;
  font-weight: normal;
}

#serverSettings section input[type="color"] {
  cursor: pointer;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
}


#serverSettings section input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

#serverSettings section input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

#serverSettings .button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

#serverSettings .button.has-changes {
  background-color: #69dc9e;
}

#serverSettings .button.no-changes {
  background-color: grey;
}

#serverSettings section button.reset {
  background-color: #f5f5f5;
  /* Adjust this value as needed */
  color: #000000;
  /* Adjust this value as needed */
  padding: 10px;
  /* Adjust this value as needed */
  margin: 10px 0;
  /* Adjust this value as needed */
  border-radius: 5px;
  /* Adjust this value as needed */
  border: none;
  /* Remove the default button border */
  cursor: pointer;
  /* Change the cursor to a pointer when hovering over the button */
}

#serverSettings section div div.Select {
  width: auto;
  /* Set width as needed */
}

#serverSettings section div div.Select .Select-menu-outer {
  background-color: white;
  /* Set background color */
  border: 1px solid #ccc;
  /* Add border */
  border-radius: 5px;
  /* Add border radius */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Add box shadow */
}

#serverSettings .backButton {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

#serverSettings .backButton a {
  font-family: Lexend;
  text-decoration: none;
  color: black;
}

#serverSettings .backButton:hover {
  background-color: #f6f6f6;
}

#serverSettings .nickname {
  background-color: #ffffff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  padding: 10px;
  border: 1px solid #ccc;
  font-weight: 500;
  font-family: "Lexend", sans-serif;
}