@font-face {
  font-family: 'Lexend';
  src: url('../../fonts/Lexend.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



.layer1 {
  background-image: "/src/assets/splash-wave.svg";
}

.spacer {
  aspect-ratio: 960/540;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


.motto {
  color: #ffffff;
  font-size: 30px;
  font-style: italic;
  margin-bottom: 30px;
}

.comingsoon {
  color: #777;
  font-size: 1em;
}

.comingsoon a {
  color: #007BFF;
  text-decoration: none;
}

.comingsoon a:hover {
  color: #0056b3;
}

.contentcontainer {
  width: 80%;
  margin: 0 auto;
}

.learnmore {
  text-align: center;
  font-size: 30px;
  padding-top: 8%;
  padding-bottom: 10%;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #5bbd88;
  margin: 15px auto;
  animation: arrow-animation 4s infinite;
}

.arrowWhite {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  margin: 15px auto;
  animation: arrow-animation 4s infinite;
}

@keyframes arrow-animation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}

.featurestitle {
  text-align: center;
  font-weight: bold;
  font-size: 80px;
  padding-top: 50px;
}


@media (max-width: 600px) {
  .featurestitle {
    font-size: 40px; /* Adjust as needed */
    padding-top: 25px; /* Adjust as needed */
  }
}


body {
  overflow-x: hidden;
}

.loginbutton {
  padding: 10px 20px;
  background-color: #69DC9E;
  color: white;
  border: 2px solid #69DC9E;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none !important; 
  font-size: 16px;
  transition: background-color 0.3s, border-color 0.3s;
}

.loginbutton:hover {
  background-color: #5bbd88;
  border-color: #5bbd88;
}

.splashtext,
.splashtext h1,
.motto,
.loginbutton,
.card-title,
.card-text {
  font-family: 'Lexend', sans-serif;
  /* Apply Lexend font to other elements */
}

.splashtexttop {
  color: #ffffff;
}

.splashtext h1 {
  color: #ffffff;
  font-size: 500%;
  margin: auto;
  transition: transform 0.3s ease;
  /* Adjust duration and easing function as needed */
  width: auto;
}

.splashtext {
  text-align: center;
  padding: 50px;
  background-color: #69DC9E;

}

.splashtext h1:hover {
  transform: scale(1.1);
}

.card p {
  padding: 10px;
  /* adjust the padding of card text */

}

.features {
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 20px;
  border-radius: 20px; /* Adjust as needed */
}

.contentcontainer {
  background-color: #2f3136;
  width: 80%;
  color: #ffffff;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 20px;
  border-radius: 20px; /* Adjust as needed */
  padding-bottom: 40px;
}

.features h3 {
  font-weight: normal;
  padding-bottom: 20px;
  
}

.featureDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.logging {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}


.featureImage {
  border-radius: 20px; /* Adjust as needed */
  margin-right: 20px; /* Adjust as needed */
  width: 50%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition */
}

.featureImage:hover {
  transform: scale(1.05); /* Make the image a bit larger */
  box-shadow: 0 0 5px rgba(255, 255, 255, 1); /* Add a glow effect */
}
.text-content {
  flex: 1;
}

@media (max-width: 600px) {
  .featureDiv {
    flex-direction: column;
  }

  .featureImage {
    margin-right: 0; /* Remove the right margin */
    margin-bottom: 20px; /* Add a bottom margin */
    width: 100%; /* Make the image take up the full width of its container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Resize the image to cover the container while maintaining its aspect ratio */
  }
}
.text-content h2, .text-content p {
  margin: 0;
}



.text-content h2 {
  font-size: 1.5em;
  padding-bottom: 20px;
}

.title {
  font-size: 3em;
  padding-bottom: 20px;
  text-align: center;
}